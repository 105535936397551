import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import FileUpload from '@/components/DSE/FileUpload';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    year: {
      type: MDinput,
      width: 100,
      props: {
        type: 'number',
        precision: 0
      }
    },
    file: {
      type: FileUpload,
      defaultValue: null,
      hideOnCreate: true,
      props: {
        caption: 'common.file',
        uploadRoute: 'package-rate-manual/file',
        deleteAction: 'packageRateManual/deleteFile',
        downloadAction: 'packageRateManual/downloadFile',
        openFileInNewWindow: true
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
